import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
// import Layout from "../layout/layout";
// import Login from "../pages/login";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  const [isLogin, setLogin] = useState(false);
  const [auth, setAuth] = useState(false);
  const [Theme, setTheme] = useState("theme-4");
  const [ThemeColor, setThemeColor] = useState("theme-color-1");
  const [MiniSidebar, setMiniSidebar] = useState("");
  const [userInfo, setUserInfo] = useState({});

//   const [questionYubObj, setQuestionYubObj] = useState();
//   const [imageCoordinate, setImageCoordinate] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState({});


  const reset = () =>{
    setLogin(false);
    localStorage.removeItem('token')
    localStorage.removeItem('userId')  
  }
  
  useEffect(()=>{
      let authLocal = reactLocalStorage.get('token');
      setLogin(authLocal);
  },[])

  
  useEffect(()=>{
    /* let thm = localStorage.getItem('theme') || "theme-1";
    let thmClr = localStorage.getItem('themeColor'); */
    let authLocal = localStorage.getItem('token');
    /* setTheme(thm);
    setThemeColor(thmClr); */
    setAuth(authLocal);
  },[])
  return (
    <UserContext.Provider
        value={{
        ThemeColor,
        Theme,
        auth,
        MiniSidebar,
        setThemeColor,
        setTheme,
        isLogin, setLogin,
        reset,
        setAuth,
        setMiniSidebar,
        userInfo, setUserInfo,
        // questionYubObj, setQuestionYubObj,
        // imageCoordinate, setImageCoordinate,
        breadcrumbs, setBreadcrumbs
      }}
    >
      {children}
    </UserContext.Provider>
  );
};