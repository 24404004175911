import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from "yup";
import apiFunc from "./api";
import millify from "millify";
import authAxios from "./authAxios";

const common = {
  milifying: (milifyData) => {
    return millify(milifyData, {
      units: ["", "K", "M", "B", "T", "P", "E"],
      space: true,
      precision: 3,
    });
  },
  loader: (type) => {
    if (type) {
      document.body.className = "loading_page";
    } else {
      document.body.className = document.body.className.replace(
        "loading_page",
        ""
      );
    }
  },
  base64Mime: (encoded) => {
    var result = null;
    if (typeof encoded !== "string") {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  },
  base64ReadFile: (base64String) => {
    var nonBlob = base64String == undefined ? 0 : base64String.length;
    var filetype = common.base64Mime(base64String);
    var datass = { size: nonBlob / 1000, type: filetype };
    return datass;
  },
  audioBase64: (blob) => {
    var reader = new window.FileReader();
    var base64;
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      base64 = reader.result;
      base64 = base64.split(",")[1];
    };
    return base64;
  },
  previewURL: (file) => {
    let URL = "";
    if (file != "") {
      URL = window.URL.createObjectURL(file);
    }
    return URL;
  },
  mineTypeValidate: (value) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileType = value.type;
    return (
      value &&
      (fileType === "image/jpeg" ||
        fileType === "image/bmp" ||
        fileType === "image/png") /* ||
      fileType === 'application/pdf' ||
      fileType === "application/msword" */
    );
  },
  fileSizeValidate: (value, size) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileSize = value.size;
    if (!fileSize) {
      fileSize = 2;
    }
    let mb = fileSize * 1024;
    return fileSize <= mb;
  },
  getMiles: (i) => {
    return (i * 0.000621371192).toFixed(1);
  },
  coupanTypeDiscount: (obj) => {
    let ctype = obj.couponType || 0;
    let price = obj.price || 0;
    let discount = obj.discount || 0;
    let minAmount = obj.minAmount || 0;
    let disUpto = obj.disUpto || 0;
    let disRate = 0;
    if (ctype == "FLAT PERCENT") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
    } else if (ctype == "FLAT PERCENT UPTO") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;
      console.log("FLAT PERCENT UPTO", disRate);
    } else if (ctype == "CASH DISCOUNT UPTO") {
      disRate = price >= minAmount ? discount : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;
      console.log("CASH DISCOUNT UPTO", disRate);
    }
    return parseFloat(disRate.toFixed(2));
  },
  isMobile: (num) => {
    var isphone =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(num);
    return isphone;
  },
  coordinateLocal: async () => {
    let coordataL;
    function asignData(data) {
      coordataL = data;
    }
    await navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      let jsonCoords = {
        lat: lat,
        lng: lng,
      };
      jsonCoords = JSON.stringify(jsonCoords);
      asignData(jsonCoords);
      localStorage.setItem("geoLocal", jsonCoords);
      // console.log('coordataL', localStorage.getItem('geoLocal'))
      // reactLocalStorage.set('geoLocal', jsonCoords);
    });
    return coordataL;
  },
  creditCardType: (cardType) => {
    let imageUrl;
    cardType = cardType.toLowerCase();
    switch (cardType) {
      case "visa":
        imageUrl = "card-logo-visa.svg";
        break;
      case "mastercard":
        imageUrl = "card-logo-mastercard.svg";
        break;
      case "american-express":
        imageUrl = "card-logo-amex.svg";
        break;
      default:
        imageUrl = "card-logo-unknown.svg";
    }
    return imageUrl;
  },
  imageValidate: (extention, size, msg) => {
    extention = extention || ["image/jpg", "image/jpeg", "image/png"];
    msg = msg || "You need to attach image";
    size = size || 1024 * 1024;
    let image = Yup.mixed()
      .required(msg)
      .test("fileSize", "The image is too large", (value) => {
        if (value == undefined || value == null) {
          return false;
        }
        return value && value?.size <= size;
      })
      .test(
        "type",
        `Only the following formats are accepted: ${extention}`,
        (value) => {
          if (value == undefined || value == null) {
            return false;
          }
          let fileType = value.type;
          console.log(fileType)
          return value && extention.indexOf(fileType) != -1;
        }
      );
    return image;
  },
  uploadImage: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.image);
    data = await apiFunc
      .postUpload(formData)
      .then((res) => {
        return res.data.data._id;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },

  uploadImage2: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await authAxios({
      method: 'POST',
      url: `/media/upload`,
      data: formData
    }).then(res => {
      return res.data.data._id
    }).catch((error) => {
      common.error(error);
    })
    common.loader(false);
    return data;
  },
  error: (error) => {
    common.loader(false);
    var message = JSON.parse(error.request.response).message;
    toast.error(message);
    return message;
  },
  generateQOID: async () => {
    let resData = null;
    resData = await apiFunc
      .getOptionId()
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        common.error(error);
      });

    return resData;
  },
  bufferToBase64: (arr) => {
    //arr = new Uint8Array(arr) if it's an ArrayBuffer
    let buffer = btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
    return "data:image/png;base64," + buffer;
  },
  getImage: async (id) => {
    if (id) {
      let data;
      data = await apiFunc
        .getImage(id)
        .then((res) => {
          return common.bufferToBase64(res.data.data.Body.data);
        })
        .catch((error) => {
          common.error(error);
        });
      return data;
    }
  },
  aadharValidate: (num) => {
    let regexp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
    return regexp.test(num);
  },
  isAlphabet: (event) => {
    console.log(event);
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    );
  },
  version: () =>{
    let date = new Date();
    let day = date.getDate() <= 15 ? 'ER' : 'LT' 
    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let monthName = (month[date.getMonth()]).toUpperCase();
    let getFullYear = ((date.getFullYear()).toString()).substr(2);

    let version = day + '-' +monthName + '-' + getFullYear
    return version

  }
};

export default common;
