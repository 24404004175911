let ROOT_URL = "";
let IMAGE_URL = "";
console.log(process.env.REACT_APP_SERVER);
if(process.env.REACT_APP_SERVER?.trim() === 'production'){
    ROOT_URL ='https://api.samskara.app/api/v1/v'
    IMAGE_URL ='https://d2yiyjum1sxmcg.cloudfront.net/'
}else{
    ROOT_URL = "https://samskara-api.appdevelop.in/api/v1/v";
    // ROOT_URL ='http://staging.alphonic.net.in:6510/api/v1/v'
    ROOT_URL ='https://api.samskara.app/api/v1/v'
    IMAGE_URL ='https://d2o16grlb19pkv.cloudfront.net/'
}
export { IMAGE_URL };
export default ROOT_URL;
