import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage';
import { UserContext } from '../context/theme';
import ROOT_URL from '../services/api-url';
import authAxios from '../services/authAxios';
import common from '../services/common';
import Moment from "react-moment";
import { Dropdown } from 'react-bootstrap';


function Header() {
  let navigate = useNavigate();
  const { isLogin, setLogin, userInfo, setUserInfo } = useContext(UserContext);
  const [profileData, setProfileData] = useState([])
  const [notificationList, setNotification] = useState(new Array());
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

 // console.log("isLogin",isLogin);
  //logout function
  function logout() {
    reactLocalStorage.clear();
    setLogin(false);
    navigate("/signin");
  }

  const calendarStrings = {
    lastDay: '[Yesterday at] LT',
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'L'
  };

  //myProfile API call for get username
  const myProfileData = async () => {
    await authAxios({
      method: "GET",
      url: `/myProfile`,
    }).then((res) => {
      setUserInfo(res.data.data);
      //console.log('res',res); 
    }).catch((error) => {
      common.error(error);
    })
  }

  function refreshtoken() {
    authAxios({
      method: "GET",
      url: `/tokenRefresh`,
    })
      .then((res) => {
        reactLocalStorage.set("token", res.data.token);
      })
      .catch((error) => {
        // reactLocalStorage.remove("token");
      });
  }


  const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen)

  const getNotification = () => {
    let notiData = {
      page: 1,
      perPage: 20
    }
    authAxios({
      method: "POST",
      url: `/notifications/pagin`,
      data: notiData
    }).then((res) => {
      setNotification(res.data)
    }).catch((error) => {
      common.error(error);
    });
  }

  // useEffect(() => {
  //   // let token=reactLocalStorage.get("token");
  //   let intrVal;
  //   if(isLogin){
  //     //console.log(isLogin, intrVal)
  //      intrVal =setInterval(() => {
  //       refreshtoken()
  //       getNotification()
  //     }, 10000);
  //   }else{
  //     clearInterval(intrVal);
  //   }
  // },[isLogin])

  //console.log(isLogin);
  // useEffect(()=>{
  //   if(isLogin){
  //     myProfileData();
  //   }
  // },[isLogin])

  const markAsRead = (id) => {
    authAxios({
      method: "POST",
      url: `/notifications/markAsRead/${id}`,
    }).then((res) => {
      getNotification()
    }).catch((error) => {
      common.error(error);
    });
  }

  const markAllRead = () => {
    authAxios({
      method: "POST",
      url: `/notifications/markAllRead`,
    }).then((res) => {
      getNotification()
    }).catch((error) => {
      common.error(error);
    });
  }


  useEffect(() => {
    let intrVal;
    console.log("isLogin",isLogin);
    if (isLogin) {
      intrVal = setInterval(() => {
        refreshtoken()
      }, 10000);
    }
    if (isLogin) {
      myProfileData();
      getNotification()
    }
    return () => clearInterval(intrVal);
  }, [isLogin])

  return (
    <>
      <div className="navbar">
        <div className="navbar-brand">
          <Link to="/dashboard"><img src="/assets/images/logo.svg" alt="logo-img" /></Link>
        </div>
        <div className='dropdownWrp'>
          {isLogin ? (
            <ul className='notification-css no-bullets notification-icon'>
              <li>
                <Dropdown show={dropdownIsOpen} onToggle={toggleDropdown} align="end" >
                  <Dropdown.Toggle variant="thm" id="dropdown-basic">
                      {notificationList?.unReadCount >0 
                      ? 
                        <>
                          <i className="fas fa-bell"></i>
                          <span className="badge bg-danger rounded-circle position-absolute">
                            {notificationList?.unReadCount}
                          </span>
                        </>
                      : 
                        <i className="fas fa-bell"></i>
                      } 
                      {/* {notificationList.length} */}
                    
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <>
                      <div className="notification_popup_inner">
                        {notificationList?.unReadCount>0 
                        ?
                          <button type="button" onClick={() => markAllRead()} className="btn markAllReadbutton">
                            Mark All As Read
                          </button>  
                        :
                          ""
                        }
                        
                        <div className="notifyInside scroller">
                          {notificationList?.unReadCount>0  ? (
                            notificationList?.result?.map((data, key) => (
                              <>
                                {data.isRead==false 
                                  ? 
                                    <div className="accountnotificationcon active" key={key}>
                                      <div className="accountnotipara">
                                        <p>{data?.description}</p>
                                      </div>
                                      <div className="accountheaderdatecon">
                                        <button type="button" onClick={() => markAsRead(data?._id)} className="btn">
                                          Mark read
                                        </button> 
                                        <Moment calendar={calendarStrings}>
                                          {data?.createdAt}
                                        </Moment>
                                      </div>
                                  </div>
                                  :
                                  ""
                                }
                              </> 
                            ))
                          ) : (
                            <div>
                              <h4>No Notifications</h4>
                            </div>
                          )
                          }
                        </div>
                        <Link to="/my-notification">
                          <span onClick={() => toggleDropdown()} className="btn cus_btn custom01 d-block"> View All </span>
                        </Link>
                      </div>
                    </>

                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="user-profile header-notification " >

                  <div className=" dropdown">
                    <div className="dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa-solid fa-user-large "></i>
                      <span className='ml-2'>{userInfo?.firstName}</span>
                      <i className="feather icon-chevron-down"></i>
                    </div>
                    <ul
                      className="dropdown-menu-end profile-notification dropdown-menu no-bullets"
                  >
                    <div className="headerusercon">

                      <li className='header-li header-dropdown'>
                        <Link to="/dashboard">
                          <img src="/assets/images/account.png" className='header-dropdown' alt="account-img" /> Account
                        </Link>
                      </li>
                      <li className='header-li header-dropdown'>
                        <Link to="/bookings">
                          <img src="/assets/images/booking3.png" className='header-dropdown' alt="booking-img" /> Bookings
                        </Link>
                      </li>
                      <li className='header-li header-dropdown'>
                        <Link to="/accounts">
                          <img src="/assets/images/earning.png" className='header-dropdown' alt="earning-img" /> Earnings
                        </Link>
                      </li>
                      <li className='header-li header-dropdown'>
                        <Link to="/get-help">
                          <img src="/assets/images/help.png" className='header-dropdown' alt="account-img" /> Help Desk
                        </Link>
                      </li>
                      <li className='header-li header-dropdown'>
                        <Link to="/" onClick={() => { logout(); }}>
                          <img src="/assets/images/logout.png" className='header-dropdown' alt="logout-img" /> Sign Out
                        </Link>
                      </li>
                    </div>

                    </ul>
                  </div>
              </li>
            </ul>

          ) : (
            <ul className="navbar-nav">
              <li className="nav-item">
                <i className="fa-solid fa-circle-user"></i>&nbsp;Sign In
              </li>
            </ul>
          )}

        </div>
      </div>
    </>
  )
}

export default Header