import React, { Suspense, useContext } from "react";
import Header from "./header";
import Footer from "./footer";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
//import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
//import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { reactLocalStorage } from "reactjs-localstorage";
//import Loader from "../components/loader/loader";
import 'react-toastify/dist/ReactToastify.css';

const SignIn = React.lazy(() => import("../pages/signIn/signIn"));
//const ForgotPassword = React.lazy(() => import("../pages/forgotPassword/forgotPassword"));
const Dashboard = React.lazy(() => import("../pages/dashboard/dashboard"));
const Accounts = React.lazy(() => import("../pages/accounts/accounts"));
const AllBookings = React.lazy(() => import("../pages/allBookings/allBookings"));
const BankDetails = React.lazy(() => import("../pages/bankDetails/bankDetails"));
const ChangePassword = React.lazy(() => import("../pages/changePassword/changePassword"));
const GetHelp = React.lazy(() => import("../pages/getHelp/getHelp"));
const BookingView = React.lazy(() => import("../pages/myBookingsList/myBookingsList"));
const MyNotification = React.lazy(() => import("../pages/myNotification/myNotification"));
const RaiseTicket = React.lazy(() => import("../pages/raiseTicket/raiseTicket"));
const TermAndConditions = React.lazy(() => import("../pages/termAndConditions/termAndConditions"));
const ViewTicket = React.lazy(() => import("../pages/viewTicket/viewTicket"));
const ChangeEmail = React.lazy(() => import("../pages/myProfilePages/changeEmail"));
const UpdatePhone = React.lazy(() => import("../pages/myProfilePages/updatePhone"));
const Address = React.lazy(() => import("../pages/myProfilePages/updateAddress"));
const GetOtp = React.lazy(() => import("../pages/myProfilePages/getOtp"));
const GetPhoneOtp = React.lazy(() => import("../pages/myProfilePages/getPhoneOtp"));
const PrivacyAndPolicy = React.lazy(() => import("../pages/privacyPolicy/privacyAndPolicy"));
const ForgotPassword = React.lazy(() => import("../pages/forgotPassword/forgotPassword"));
const VerifyOtp = React.lazy(() => import("../pages/verifyOtp/verifyOtp"));
const ResetPassword = React.lazy(() => import("../pages/resetPassword/resetPassword"));

function Layout() {
    const { userInfo } = useContext(UserContext);

    
  return (
    <div className={`page-wrapper`}>      
    <Router>
  
        <div className="all-content-wrapper">    
             <Header />
              <Suspense fallback={"loading"}>
                <ToastContainer />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />}/>
                <Route path="/" element={<SignIn/>} />
                <Route path="/signin" element={<SignIn/>} />
                <Route path="/forgotPassword" element={<ForgotPassword/>} />
                <Route path="/verify-otp" element={<VerifyOtp/>} />
                <Route path="/reset-password" element={<ResetPassword/>} />       
                <Route path="/accounts" element={<Accounts/>} />
                <Route path="/bookings" element={<AllBookings/>} />
                <Route path="/bookings/:orderId" element={<BookingView/>} />
                <Route path="/bank-details" element={<BankDetails/>} />
                <Route path="/change-password" element={<ChangePassword/>} />
                <Route path="/get-help" element={<GetHelp/>} />
                <Route path="/my-notification" element={<MyNotification/>} />
                <Route path="/raiseticket" element={<RaiseTicket/>} />
                <Route path="/term-and-conditions" element={<TermAndConditions/>} />              
                <Route path="/view-ticket/:id" element={<ViewTicket/>} />
                <Route path="/change-email" element={<ChangeEmail/>} />
                <Route path="/change-phone" element={<UpdatePhone/>} />
                <Route path="/change-address" element={<Address/>} />
                <Route path="/getOtp" element={<GetOtp/>} />
                <Route path="/getphoneOtp" element={<GetPhoneOtp/>} />
                <Route path="/privacy-policy" element={<PrivacyAndPolicy/>} />
              </Routes>
              </Suspense>
        </div>
        <Footer/>
        
        </Router>
    </div>
  )
}

export default Layout