import './App.css';
import React, { useState } from 'react'
import { UserContext, UserProvider } from "./context/theme";
import Layout from "./layout/layout";

function App() {
  return (
    <>
      <UserProvider>
        <Layout/>
      </UserProvider>
    </>
  );
}

export default App;
